import React from 'react'
import * as timeago from 'timeago.js'

import TimeAgo from 'timeago-react' // var TimeAgo = require('timeago-react');
import en_short from 'timeago.js/lib/lang/en_short'

timeago.register('en_short', en_short)

export const TimeAgoShort = ({ ...props }) => {
  return <TimeAgo {...props} locale="en_short" />
}
