import React from 'react'
import WaterIcon from '@material-ui/icons/LocalDrink'
import PhotoIcon from '@material-ui/icons/Collections'
import FertilizeIcon from '@material-ui/icons/ScatterPlot'
import RepotIcon from '@material-ui/icons/SaveAlt'
import RotateIcon from '@material-ui/icons/Loop'
import MistIcon from '@material-ui/icons/Waves'
import { Api } from 'jetset'

export const eventTypeConfig = {
  water: {
    color: 'primary',
    icon: <WaterIcon />,
  },
  photo: {
    color: 'primary',
    icon: <PhotoIcon />,
  },
  fertilize: {
    color: 'secondary',
    icon: <FertilizeIcon />,
  },
  repot: {
    color: 'secondary',
    icon: <RepotIcon />,
  },
  rotate: {
    color: 'primary',
    icon: <RotateIcon />,
  },
  mist: {
    color: 'primary',
    icon: <MistIcon />,
  },
}

export const getEventTypeColor = eventType => {
  return eventTypeConfig[eventType].color
}
export const getEventTypeIcon = eventType => {
  return eventTypeConfig[eventType].icon
}

export const useEventApi = Component => (
  <Api url={process.env.API_URL} events="/events">
    <Component />
  </Api>
)
