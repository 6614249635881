import React from 'react'
import PropTypes from 'prop-types'

import TimelineDot from '@material-ui/lab/TimelineDot'
import { getEventTypeColor, getEventTypeIcon } from '../services/events'

const EventTypeIcon = ({ eventType, classes }) => {
  return (
    <TimelineDot className={classes} color={getEventTypeColor(eventType)}>
      {getEventTypeIcon(eventType)}
    </TimelineDot>
  )
}

EventTypeIcon.propTypes = {
  eventType: PropTypes.string.isRequired,
  classes: PropTypes.array,
}

export default EventTypeIcon
