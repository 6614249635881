import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { Link } from 'gatsby'

const EventActorPhrase = ({ actor, eventType }) => {
  let verb
  switch (eventType) {
    case 'fertilize':
      verb = 'was fertilized'
      break
    case 'rotate':
      verb = 'was rotated'
      break
    case 'repot':
      verb = 'was repotted'
      break
    case 'photo':
      verb = 'has a new picture'
      break
    default:
      verb = `was ${eventType}ed`
  }
  return (
    <Typography variant="subtitle2" component="strong">
      <Typography
        variant="body2"
        color="primary"
        component={Link}
        to={`/plant-profile/${actor}`}
      >
        @{actor}
      </Typography>{' '}
      {verb}
    </Typography>
  )
}

EventActorPhrase.propTypes = {
  actor: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
}

export default EventActorPhrase
